import { useEffect, useState } from 'react';
import Square, { TSquare } from './square/Square';

export interface IGridProps {
  squares: TSquare[],
  sideCount: number,
  onStart: Function,
  loaded: boolean,
  gameFailed: boolean,
  onClickHandler: (index: number) => void
}

function Grid(props: IGridProps) {
  const SIDE_LENGTH = 24;

  useEffect(() => {
    if (!props.loaded) return;
    props.onStart();

    // eslint-disable-next-line
  }, [props.loaded]);

  return (
    <div className={`
      inline-grid
      gap-x-4
      gap-y-4
      align-top
    `} style={{ 
      gridTemplateColumns: `repeat(${props.sideCount}, 1fr)`,
      gridTemplateRows: `repeat(${props.sideCount}, 1fr)`
    }}>
      {props.squares.map(
        (square: TSquare) => {
          return <Square gameFailed={props.gameFailed} key={square.index} square={square} sideCount={props.sideCount} sideLength={SIDE_LENGTH} onClickHandler={props.onClickHandler} />
        } 
      )}
    </div>
  );
}

export default Grid;
import React, { useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import './_style.scss';
import DialogElement from '../../utils/dialog/Dialog';

export interface IMiniGameWidgetProps {
  name: string;
  image: string;
  description: string;
  example: string;
}

function MiniGameWidget(props: IMiniGameWidgetProps) {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  const DialogElement = () =>{
    return (
      <>
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="absolute z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>
  
            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-gainsboro p-6 text-left align-middle shadow-xl transition-all text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {props.name}
                    </Dialog.Title>
                    <div className="mt-2">
                      <img src={props.example} alt='' />
                    </div>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {props.description}
                      </p>
                    </div>
  
                    <div className="mt-4">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-amethyst-smoke px-4 py-2 text-sm font-medium text-white-smoke hover:bg-blackcurrant focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={closeModal}
                      >
                        Got it!
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </>
    )
  }

  return (
    <>
      <DialogElement />
      <div onClick={openModal} className='widget drop-shadow-md cursor-pointer relative lg:my-0 my-3 min-w-[12rem] lg:w-3/12 w-8/12 '>
        <div className='content bg-rum flex flex-col justify-center items-center px-3 py-3 text-center w-full h-full relative'>
          <img className='noselect' draggable='false' src={props.image} alt=''></img>
          <h2 className='mt-3 font-montserrat font-bold text-gainsboro text-xl whitespace-nowrap'>{ props.name }</h2>
        </div>
      </div>
    </>
  )
}

export default MiniGameWidget
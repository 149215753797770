import { Player } from "../players/PlayerListItem";

export type Message = {
  message: string,
  author: Player
}

export interface IMessage {
  message: Message
}

function MessageItem(props: IMessage) {
  return (
    <div className="text-gainsboro w-full h-fit odd:bg-amethyst-smoke even:bg-rum font-poppins px-4 py-2">
      <p>
        <b className={props.message.author && props.message.author.hasLost ? 'line-through ' : ''}>{props.message.author.username}</b>
        <b>{props.message.author && props.message.author.hasLost ? '💀' : ''}: </b>
        <span>{props.message.message}</span>
      </p>
      
    </div>
  );
}

export default MessageItem;
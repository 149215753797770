import { ESquareAnimation, TSquare } from "./square/Square";

const createSquares = (squareCount: number, animation: ESquareAnimation) => {
  const temp: TSquare[] = [];
  
  for (let i = 0; i < squareCount; i++) {
    temp.push({
      index: i,
      pressed: false,
      animation: animation
    });
  }

  return temp;
}

const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export { createSquares, sleep }
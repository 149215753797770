import React, { PropsWithChildren, ReactNode, useContext, useEffect, useReducer, useState } from 'react';
import { SocketContextProvider, SocketReducer, SpectatingInput, defaultSocketContextState } from './Context';

import { useSocket } from '../../hooks/useSockets';
import { useNavigate, useParams } from 'react-router-dom';
import { Player } from '../../components/minigames/players/PlayerListItem';
import AuthContext from '../authentication/Context';
import GameContext, { GameSettings } from '../game/Context';
import { Message } from '../../components/minigames/chat/Message';
import { GameType } from '../../pages/game/Game';

export interface ISocketContextComponentProps extends PropsWithChildren {
  children: ReactNode
}

const SocketContextComponent: React.FunctionComponent<ISocketContextComponentProps> = (props) => {
  const { children } = props;

  const [SocketState, SocketDispatch] = useReducer(SocketReducer, defaultSocketContextState);
  const { user } = useContext(AuthContext).AuthState
  const gameDispatch = useContext(GameContext).GameDispatch;
  const [loading, setLoading] = useState(true);

  const { rid } = useParams();
  
  let port = '';
  if (window.location.port !== '') { port = ':' + 5000 + '/'; }

  const socket = useSocket(window.location.hostname + port, { 
    reconnectionAttempts: 5, 
    reconnectionDelay: 5000, 
    autoConnect: false,
    query: { 
      rid, 
      userUid: user?.uid,
      userUsername: user?.username,
      noUser: !user
    }
  });

  const navigate = useNavigate();

  useEffect(() => {
    /** Connect to the web socket */
    socket.connect();

    /** Save the socket in context */
    SocketDispatch({type: "update_socket", payload: socket});

    /** Pass in current room id */
    SocketDispatch({ type: "update_rid", payload: rid as string });

    /** Start the event listeners */
    StartListener();

    /** Send the handshake */
    SendHandshake();

    // eslint-disable-next-line
  }, []);

  const StartListener = () => {
    /** Room ID Not Valid */
    socket.on("room_dne", () => {
      SocketDispatch(({ type: "update_rid", payload: "" }));
      navigate('/'); // add a landing page to tell the user the game has closed/ended and let them come back to the starting page
    });

    /** User connected event */
    socket.on("user_connected", (users: Player[]) => {
      console.log("User connected; new user list recieved...");
      SocketDispatch({ type: "update_users", payload: users });
    });

    /** User disconnected event */
    socket.on("user_disconnected", (socketId: string) => {
      console.log("User disconnected");
      SocketDispatch({ type: "remove_user", payload: socketId });
    });

    /** Reconnect */
    socket.io.on("reconnect", (attempt) => {
      console.log("Reconnect on attempt: " + attempt);
    });

    socket.io.on("reconnect_attempt", (attempt) => {
      console.log("Reconnection attempt: " + attempt);
    });

    socket.io.on("reconnect_error", (error) => {
      console.log("Reconnection error: " + error);
    });

    socket.io.on("reconnect_failed", () => {
      console.log("Reconnection failure");
      alert("Some went wrong when reconnecting...");
    });

    // @ts-ignore
    socket.on("updated_players", (players: Player[]) => {
      SocketDispatch({ type: "update_users", payload: players });
    });

    socket.on("picked_games", (games: GameType[]) => {
      SocketDispatch({ type: 'update_chosen_games', payload: games });
      SocketDispatch({ type: 'update_current_game', payload: games[0] })
    });

    socket.on("new_chat_message", (payload: Message) => {
      SocketDispatch({ type: "add_chat_message", payload });
    });

    socket.on("winner_decided", (player: Player) => {
      SocketDispatch({ type: 'update_game_winner', payload: player });
    });

    socket.on("new_current_game", (currentGame: GameType) => {
      SocketDispatch({ type: 'update_current_game', payload: currentGame });
    });

    socket.on("end_game", () => {
      SocketDispatch({ type: 'finish_game', payload: undefined });
    });
  }

  const SendHandshake = () => {
    console.log("Sending handshake to server...");

    socket.emit("handshake", (uid: string, users: Player[], gameSettings: GameSettings) => {
      console.log("User handshake callback message received.");
      SocketDispatch({ type: 'update_uid', payload: uid });
      SocketDispatch({ type: 'update_users', payload: users });

      gameDispatch({ type: 'update_game_settings', payload: gameSettings });

      setLoading(false);
    });
  }

  if (loading) return <p>Loading up socket.io...</p>

  return <SocketContextProvider value={{SocketState, SocketDispatch}}>{children}</SocketContextProvider>;
}

export default SocketContextComponent;
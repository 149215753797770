import { useEffect, useRef, useState } from "react";
import "./Chat.css";

import MessageItem, { Message } from "./Message";
import { Socket } from "socket.io-client";

import ChatSvg from '../../../assets/minigame/chat.svg';
import CloseSvg from '../../../assets/minigame/close.svg';

export interface IChat {
  messages: Message[],
  socket: Socket
}

function Chat(props: IChat) {
  const [message, setMessage] = useState("");
  const [isOpen, setOpen] = useState(false);

  const chatRef = useRef(null);

  useEffect(() => {
    scrollToBottomChat();
  }, [props.messages]); // @ts-disable-line

  const scrollToBottomChat = () => {
    if (props.messages.length > 0) {
      const lastChildElement = (chatRef.current! as HTMLElement).lastElementChild;
      lastChildElement?.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOpen(event.target.checked);
  }

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  }

  const sendMessage = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.socket.emit('send_chat_message', { message, isSystem: false });
    setMessage("");
  }

  return (
    <div>
      <input type="checkbox" id="show" className="hidden" onChange={handleCheckboxChange} />
      <label htmlFor="show" className="noselect fixed right-5 bottom-10 w-16 h-16 bg-amethyst-smoke rounded-full flex justify-center items-center drop-shadow-lg">
        {
          !isOpen ? (<img className="icon" width={25} height={25} src={ChatSvg} alt=""></img>) : (<img className="icon" width={35} height={35} src={CloseSvg} alt=""></img>) 
        }
      </label>
      <div className="hidden wrapper bg-amethyst-smoke fixed right-5 bottom-10 rounded-bl-xl rounded-br-xl rounded-tl-none rounded-rl-none my-20 w-80 h-[400px] drop-shadow-xl border-[#7e7681] border-2">
        <div ref={chatRef} className="messages overflow-y-auto w-full h-5/6">
          {
            props.messages.map((message, index) => 
              <MessageItem key={index} message={message} />
            )
          }
        </div>
        <form className="px-3 mt-3 w-full bottom-0" onSubmit={sendMessage}>
          <input type="text" className="w-full py-2 px-3 rounded-sm border-0 outline-0 font-poppins" onChange={handleMessageChange} value={message} />
          <input type="submit" className="hidden" />
        </form>
      </div>
    </div>
  );
}

export default Chat;
import { Player } from "../players/PlayerListItem";

export interface IHeaderProps {
  round: number,
  gameName: string,
  selfPlayer: Player,
  spectatingPlayer: Player,
  players: Player[]
}

function Header(props: IHeaderProps) {
  const Spectating = () => {
    if (props.selfPlayer.uid !== props.spectatingPlayer.uid) {
      const player = props.players.find((player) => props.spectatingPlayer.uid === player.uid);
      return <h2 className="font-montserrat font-light text-xl">{`👁️ ${player?.username}`}</h2>
    }

    return <></>
  }

  return (
    <div className="bg-blackcurrant w-full h-14 mb-2 text-gainsboro flex items-center justify-between px-8">
      <h2 className="font-montserrat font-bold text-xl">Level {props.round}</h2>
      <Spectating />
      <h2 className="font-montserrat font-light text-xl underline underline-offset-8 decoration-1">{props.gameName}</h2>
    </div>
  );
}

export default Header;
export enum FieldType {
  Password = 'password',
  Email = 'email',
  Text = 'text'
}

export interface IInputFieldProps {
  placeholder: string,
  type: FieldType,
  pattern?: string,
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

function InputField(props: IInputFieldProps) {
  return (
    <input onChange={props.handleChange} type={props.type} pattern={props.pattern} className="w-full px-4 py-3 my-2 outline-0 bg-gainsboro" placeholder={props.placeholder} />
  );
}

export default InputField;
import React from 'react'
import MiniGameWidget from '../minigame/MiniGameWidget'

import VisualMemoryDesign from '../../../assets/home/VisualMemoryDesign.svg';
// import VerbalMemory from '../../../assets/home/VerbalMemory.svg';
import SequenceMemory from '../../../assets/home/SequenceMemory.svg';
import YouTubeEmbedded from '../../utils/video/YouTubeEmbeded';

import SequenceMemoryExample from '../../../assets/minigame/examples/example_sequence_memory.gif';
import VisualMemoryExample from '../../../assets/minigame/examples/example_visual_memory.gif';

type MiniGameWidget = {
  name: string,
  description: string,
  image: string,
  example: string
}

function HomeContent() {

  const miniGameWidgets: MiniGameWidget[] = [
    {
      name: 'Visual Memory',
      image: VisualMemoryDesign,
      description: 'Look at it...and memorize it!',
      example: VisualMemoryExample
    },
    {
      name: 'Sequence Memory',
      image: SequenceMemory,
      description: 'Remember you\'re iPhone pin?',
      example: SequenceMemoryExample
    },
  ]

  return (
    <div className='px-0'>
      <div className='pb-12'></div>
      <div className='flex justify-around lg:flex-row flex-col items-center lg:items-start'>
        {
          miniGameWidgets.map((widget: MiniGameWidget, index) => <MiniGameWidget key={index} name={widget.name} image={widget.image} description={widget.description} example={widget.example} />)
        }
      </div>
      <div className='flex flex-row justify-center mt-10 pb-8'>
        <div className='w-11/12'>
          <YouTubeEmbedded embedId='efPK-NjbiQI' />
        </div>
      </div>
      <div className='font-montserrat pb-12 pr-9 text-[#99919C] text-right'>
        <p>Copyright 2023 Genus</p>
        <p>contact@genus.com</p>
      </div>
    </div>
  )
}

export default HomeContent
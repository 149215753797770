import { gql } from "@apollo/client";

const USER_SUCCESS = gql`
  query User {
    userSuccess {
      username,
      uid,
      email
    }
  }
`

export { USER_SUCCESS };
import { useEffect, useState } from "react";
import PlayerListItem, { Player } from "./PlayerListItem";

export interface IPlayerListProps {
  players: Player[],
  currentPlayer: Player,
  updateCurrentPlayer: Function,
  selfPlayer: Player,
  gameFailed: boolean
}

function PlayerList(props: IPlayerListProps) {
  return (
    <div className="order-2 lg:order-1 lg:mr-5 lg:mt-0 mt-5 w-fit">
      {
        props.players.map((player: Player, index: number) => {
          return <PlayerListItem gameFailed={props.gameFailed} key={index} player={player} active={props.currentPlayer.uid === player.uid} updateCurrentPlayer={props.updateCurrentPlayer} />
        })
      }
    </div>
  );
}

export default PlayerList;
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserInfo } from '../../../pages/authentication/login/Login';
import AuthContext from '../../../contexts/authentication/Context';
import { useMutation } from '@apollo/client';
import { LOGOUT_USER } from '../../../graphql/mutations/authenticationMutations';

export interface INavbarProps {
  user: UserInfo | undefined
}

function Navbar(props: INavbarProps) {
  const navigate = useNavigate();
  const dispatch = useContext(AuthContext).AuthDispatch;

  const [logout] = useMutation(LOGOUT_USER);

  const Profile = () => {
    if (!props.user) {
      return (
        <>
          <div className='mx-5 hover-underline-animation cursor-pointer' onClick={() => navigate('sign-up')}>Sign Up</div>
          <div className='mx-5 hover-underline-animation cursor-pointer' onClick={() => navigate('login')}>Login</div>
        </>
      );
    }

    return (
      <>
        <div className='mx-5 hover-underline-animation cursor-pointer' onClick={() => navigate('profile')}>Profile</div>
        <div className='mx-5 hover-underline-animation cursor-pointer' onClick={async () => {
          await logout();
          dispatch({ type: 'logout', payload: undefined });
          navigate('/');
        }}>Logout</div>
      </>
    );
  }

  return (
    <div className='bg-gainsboro bg-dots h-14 lg:px-40 flex items-center lg:justify-between justify-around font-montserrat text-blackcurrant'>
      <h2 className='text-3xl font-bold'>Genus</h2>
      <div>
        <Profile />
      </div>
    </div>
  )
}

export default Navbar
import './Game.css';

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import SocketContext from '../../contexts/socket/Context';
import Header from '../../components/minigames/header/Header';
import SequenceMemory from '../../components/minigames/SequenceMemory';
import PlayerList from '../../components/minigames/players/PlayersList';
import Chat from '../../components/minigames/chat/Chat';
import { Player } from '../../components/minigames/players/PlayerListItem';
import GameContext, { GameSettings } from '../../contexts/game/Context';
import VisualMemory from '../../components/minigames/VisualMemory';
import { Message } from '../../components/minigames/chat/Message';
import GamePicking from '../../components/minigames/picking/GamePicking';
import { TSquare } from '../../components/minigames/grid/square/Square';

export interface IGameProps {}

export type GameType = 'Sequence Memory' | 'Visual Memory';

export function useForceUpdate(){
  const [value, setValue] = useState(0);
  return () => setValue(value => value + 1);
}

const Game: React.FunctionComponent<IGameProps> = (props) => {
  const { socket, uid, users, messages, currentGame, chosenGames, gameWinner, gameFinished } = useContext(SocketContext).SocketState;
  const { gameSettings } = useContext(GameContext).GameState;

  const socketDispatch = useContext(SocketContext).SocketDispatch;

  const [selfPlayer, setSelfPlayer] = useState<Player>({ username: "Nastalgia", uid: uid, socketId: socket?.id as string, hasLost: false }); // NEEDS UPDATE
  const [currentPlayer, setCurrentPlayer] = useState<Player>({ username: "Rex", uid: uid, socketId: socket?.id as string, hasLost: false });

  const [finishedGames, setFinishedGames] = useState<GameType[]>([]);

  const loadedIn = useRef(false);
  const [transitionEnded, setTransitionEnded] = useState<boolean>(false);

  const forceUpdate = useForceUpdate();

  const copyInputRef = useRef<null | HTMLInputElement>(null);

  // game stuff
  const [currentGameSolution, setCurrentGameSolution] = useState<number[]>([]);
  const playedHint = useRef(false);
  const [level, setLevel] = useState<number>(1);
  const [gameFailed, setGameFailed] = useState<boolean>(false);

  
  useEffect(() => {
    if (gameWinner) {
      handleGameWin();
    }
  }, [gameWinner])

  useEffect(() => {
    const cond = users.length >= gameSettings.maxPlayersCount;

    if (!loadedIn.current && cond) {
      loadedIn.current = cond;
      forceUpdate();
    }

  }, [users]);

  if (!socket?.id) return <div>Waiting for connection.</div>;

  const updateCurrentPlayer = (player: Player) => {
    setCurrentPlayer(player);
    // switch to player 
  }

  const handleGameLost = () => {
    console.log(selfPlayer.uid);
    const getPlayer = users.find((user) => currentPlayer.uid === user.uid);
    socket.emit('send_chat_message', { message: `${getPlayer?.username} has died at level ${level} in ${currentGame}.`, isSystem: true });
    
    socket.emit('game_loss', uid);

    const userMap = users.reduce((acc: { [uid: string]: Player }, player: Player) => {
      acc[player.uid] = player;
      return acc;
    }, {});

    const newMessages: Message[] = messages.map((message) => {
      if (message.author.uid === 'system') return message;
      return { ...message, author: userMap[message.author.uid] };
    });
    socketDispatch({ type: 'update_messages', payload: newMessages });
  }

  const handleGameWin = async () => {
    setCurrentGameSolution([]);
    setLevel(1);
    playedHint.current = false;
    setTransitionEnded(false);
    setGameFailed(false);

    if (!gameFinished) {
      const newPlayers = users.map((player, index) => { return { ...player, hasLost: false }; });
      socketDispatch({ type: 'update_users', payload: newPlayers });
      setTransitionEnded(false);
    }
  }

  const handleTransitionFinished = () => {
    setTransitionEnded(true)
  }

  const handleFocusEvent = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!copyInputRef.current) return;
    copyInputRef.current.select();
  };

  const handleCopy = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!copyInputRef.current) return;
    copyInputRef.current.select();
    navigator.clipboard.writeText(copyInputRef.current.value);

    const oldText = copyInputRef.current.value;
    copyInputRef.current.value = "Copied!";
    setTimeout(() => {
      if (!copyInputRef.current) return;
      copyInputRef.current.value = oldText;
    }, 2000);
  };

  const CopyLinkPrompt = () => {
    return (
      <div className="max-w-sm flex my-2">
        <input ref={copyInputRef} type="text" className="grow px-1 text-lg border border-amethyst-smoke border-r-[0] outline-0 bg-gainsboro hover:bg-[#eeeeee]" value={window.location.href} readOnly onFocus={handleFocusEvent} />
        <button type="button" className="shrink-0 w-fit h-full flex items-center justify-center bg-[#dddddd] outline-0 border border-amethyst-smoke cursor-pointer hover:bg-[#cccccc]" onClick={handleCopy}>
          <span className="text-sm">Copy</span>
        </button>
      </div>
    );
  }

  const QueueScreen = (gameSettings: GameSettings) => {
    return (
      <GameContext.Consumer>
        {
          context => (
            <div className='w-full h-screen flex flex-col justify-center font-montserrat text-2xl items-center'>
              <h1 className='text-lg'>Waiting for more players...</h1>
              <h1>{ users.length } / {gameSettings.maxPlayersCount} Players</h1>
              <CopyLinkPrompt />

              <div className='flex'>
                <div className="wave"></div>
                <div className="wave"></div>
                <div className="wave"></div>
                <div className="wave"></div>
                <div className="wave"></div>
                <div className="wave"></div>
                <div className="wave"></div>
                <div className="wave"></div>
                <div className="wave"></div>
                <div className="wave"></div>
              </div>
            </div>
          )
        }
      </GameContext.Consumer>
    );
  }
  
  const setCurrentGameSolutionFn = (ans: number[]) => {
    setCurrentGameSolution(ans);
  }

  const setPlayedHintFn = (val: boolean) => {
    playedHint.current = val;
  }

  const setLevelFn = (val: number) => {
    setLevel(val);
  }

  const setGameFailedFn = (val: boolean) => {
    setGameFailed(val);
  }

  const ActualGame = () => {
    switch (currentGame) {
      case 'Visual Memory':
        return <VisualMemory gameFailed={gameFailed} setGameFailed={setGameFailedFn} level={level} setLevel={setLevelFn} playedHint={playedHint.current} setPlayedHint={setPlayedHintFn} currentGameSolution={currentGameSolution} setCurrentGameSolution={setCurrentGameSolutionFn} transitionEnded={transitionEnded} handleGameLost={handleGameLost} />
      case 'Sequence Memory':
        return <SequenceMemory gameFailed={gameFailed} setGameFailed={setGameFailedFn} level={level} setLevel={setLevelFn} playedHint={playedHint.current} setPlayedHint={setPlayedHintFn} currentGameSolution={currentGameSolution} setCurrentGameSolution={setCurrentGameSolutionFn} transitionEnded={transitionEnded} handleGameLost={handleGameLost} />
    }
  }

  const GameScreen = () => {
    return (
      <>
        {
          !transitionEnded || gameFinished ? <GamePicking gameFinished={gameFinished} gameWinner={gameWinner} updateFinishedGames={setFinishedGames} chosenGames={chosenGames} finishedGames={finishedGames} handleTransitionFinished={() => handleTransitionFinished()} currentGame={currentGame} /> : <></>
        }
        <div className="relative w-full h-screen lg:px-10 lg:pt-9 pt-3">
          <Header 
            round={level} 
            selfPlayer={selfPlayer} 
            spectatingPlayer={currentPlayer} 
            players={users} 
            gameName={currentGame}
          />
          <div className="w-full flex flex-col lg:flex-row justify-center mx-0 lg:items-start items-center">
            <ActualGame />
            <PlayerList gameFailed={gameFailed} selfPlayer={selfPlayer} players={users} currentPlayer={currentPlayer} updateCurrentPlayer={updateCurrentPlayer} />
          </div>
          <Chat messages={messages} socket={socket} />
        </div>
      </>
    );
  }

  return (
    <div>
      {<GameContext.Consumer>
        {
          context => ( 
            loadedIn.current ? GameScreen() : 
            QueueScreen(context.GameState.gameSettings)
          )
        }
      </GameContext.Consumer>}
    </div>
  );
}

export default Game;
import { createContext } from 'react';
import { UserInfo } from '../../pages/authentication/login/Login';

export type LoginInput = {
  username: string,
  password: string
}

export type RegisterInput = {
  username: string,
  email: String,
  password: string
}

export interface IAuthContextState {
  user: UserInfo | undefined
}

export const defaultAuthContextState: IAuthContextState = {
  user: undefined
}

export type TAuthContextActions = 'login' | 'register' | 'logout';

export type TAuthContextPlayload = UserInfo | undefined;

export interface IAuthContextActions {
  type: TAuthContextActions,
  payload: TAuthContextPlayload
}

export const AuthReducer = (state: IAuthContextState, action: IAuthContextActions): IAuthContextState => {
  // console.log(`Message Received - Action: ${action.type} - Payload: `, action.payload);

  switch (action.type) {
    case 'login':
      return { ...state, user: action.payload as UserInfo };
    case 'register':
      return { ...state, user: action.payload as UserInfo };
    case 'logout': 
      return { ...state, user: undefined };
    default:
      return { ...state };
  }
}

export interface IAuthContextProps {
  AuthState: IAuthContextState,
  AuthDispatch: React.Dispatch<IAuthContextActions>;
}

const AuthContext = createContext<IAuthContextProps>({
  AuthState: defaultAuthContextState,
  AuthDispatch: () => {}
});

export const AuthContextConsumer = AuthContext.Consumer;
export const AuthContextProvider = AuthContext.Provider;

export default AuthContext;


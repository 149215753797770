import { useState } from 'react';
import JoinGameButton from './JoinGameButton';
import PlayerCountButton from './PlayerCountButton';

function Header() {
  const [playerCount, setPlayerCount] = useState<number>(2);

  const handleClick = (event: React.MouseEvent<HTMLElement>, count: number) => {
    setPlayerCount(count);
  }

  return (
    <div className='bg-blackcurrant h-64 flex flex-col justify-center items-center text-gainsboro font-montserrat'>
      <h1 className='lg:text-5xl font-bold text-3xl'>GENUS</h1>
      <h3 className='lg:text-2xl text-md'>Measure your abilities against your "friends".</h3>
      <div className='py-2 flex'>
        <PlayerCountButton handleClick={handleClick} playerCountOption={2} currentPlayerCountOption={playerCount} />
        <PlayerCountButton handleClick={handleClick} playerCountOption={3} currentPlayerCountOption={playerCount} />
        <PlayerCountButton handleClick={handleClick} playerCountOption={4} currentPlayerCountOption={playerCount} />
      </div>
      <div className='py-3 flex'>
        <JoinGameButton createRoomCount={playerCount} />
      </div>
    </div>
  )
}

export default Header
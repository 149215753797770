import { createContext } from "react";

export interface GameSettings {
  maxPlayersCount: number
}

export interface IGameContextState {
  gameSettings: GameSettings;
}

export const defaultGameContextState: IGameContextState = {
  gameSettings: {
    maxPlayersCount: 10
  }
}

export type TGameContextActions = 'update_game_settings';

export type TGameContextPayload = GameSettings;

export interface IGameContextActions {
  type: TGameContextActions,
  payload: TGameContextPayload
}

export const GameReducer = (state: IGameContextState, action: IGameContextActions): IGameContextState => {
  // console.log(`Message Received - Action: ${action.type} - Payload: `, action.payload);

  switch (action.type) {
    case 'update_game_settings':
      return { ...state, gameSettings: action.payload as GameSettings };
    default:
      return { ...state };
  }
}

export interface IGameContextProps {
  GameState: IGameContextState,
  GameDispatch: React.Dispatch<IGameContextActions>;
}

const GameContext = createContext<IGameContextProps>({
  GameState: defaultGameContextState,
  GameDispatch: () => {}
});

export const GameContextConsumer = GameContext.Consumer;
export const GameContextProvider = GameContext.Provider;

export default GameContext;

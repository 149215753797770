import { useEffect } from "react";
import HomeContent from "../components/home/content/HomeContent";
import Header from "../components/home/header/Header";
import Navbar from "../components/home/navbar/Navbar";
import AuthContext, { AuthContextConsumer } from "../contexts/authentication/Context";
import { UserInfo } from "./authentication/login/Login";

interface IHomeProps {
  user: UserInfo | undefined
}

function Home(props: IHomeProps) {
  useEffect(() => {
    document.title = "Genus";  
  }, []);

  return (
    <AuthContext.Consumer>
      {
        context => {
          return (
            <>
              <div className='w-full h-full bg-gainsboro bg-dots block'>
                <div>
                  <Navbar user={context.AuthState.user} />
                  <Header />
                  <div className='mx-auto my-0 relative max-w-4xl'>
                    <HomeContent />
                  </div>
                </div>
              </div>
            </>
          );
        }
      }
    </AuthContext.Consumer>
  );
}

export default Home;
export interface IYouTubeEmbeddedProps {
  embedId: string
}

const YouTubeEmbedded = (props: IYouTubeEmbeddedProps) => {
return (
  <div className="overflow-hidden relative h-0 pb-[50%]">
    <iframe
      className='left-0 top-0 w-full h-full absolute border-0'
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${props.embedId}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);
}

export default YouTubeEmbedded;
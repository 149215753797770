import './App.css';

import React from 'react';

import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import RouterComponent from './routes/Component';

const link = createHttpLink({
  uri: "/graphql"
});

const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});

function App() {
  return (
    <ApolloProvider client={client}>
      <RouterComponent />
    </ApolloProvider>
  );
}

export default App;

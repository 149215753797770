import './_style.scss';

export enum ESquareAnimation {
  Flip,
  Highlight
}

export type TSquare = {
  index: number,
  pressed: boolean,
  animation: ESquareAnimation
}

export interface ISquareProps {
  square: TSquare,
  sideCount: number,
  sideLength: number,
  gameFailed: boolean,
  onClickHandler: (index: number) => void
}

function Square(props: ISquareProps) {

  const createCards = () => {
    return (
      <>
        <div className={'flipped-front ' + (!props.gameFailed ? 'bg-amethyst-smoke' : 'bg-red-600 ')}></div>
        <div className='flipped-back bg-rum'></div>
      </>
    );
  }

  return <div className={
    (!props.gameFailed && props.square.animation === ESquareAnimation.Highlight ? (!props.square.pressed ? 'bg-amethyst-smoke ' : 'bg-blackcurrant ') : '') + 
    (!props.gameFailed && props.square.animation === ESquareAnimation.Flip ? (!props.square.pressed ? 'flipped ' : 'flipped pressed ') : ' ') + (props.gameFailed ? 'bg-red-600 ' + (props.square.animation === ESquareAnimation.Flip ? 'flipped ' : '') : ' ') +  
    `rounded-md before:content-[""] before:pb-full before:block transition duration-75 cursor-pointer`
  } style={{ width: `${(props.sideLength * 0.80) / props.sideCount}rem`, height: `${(props.sideLength * 0.80) / props.sideCount}rem`, }} onClick={() => props.onClickHandler(props.square.index)}>
    {props.square.animation === ESquareAnimation.Flip ? createCards() : <></>}
  </div>
}

export default Square;
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { CREATE_ROOM } from '../../../graphql/mutations/socketMutations';
import GameContext, { GameSettings } from '../../../contexts/game/Context';

interface IJoinButtonProps {
  createRoomCount: number
}

function JoinGameButton(props: IJoinButtonProps) {
  const navigate = useNavigate();

  const [createRoom] = useMutation(CREATE_ROOM);
  const dispatch = useContext(GameContext).GameDispatch;

  const handleClick = async () => {
    const gameSettings: GameSettings = {
      maxPlayersCount: props.createRoomCount
    }

    dispatch({ type: 'update_game_settings', payload: gameSettings })

    const rid: string = (await createRoom({ variables: { input: gameSettings } })).data.createRoom.rid;
    navigate(`game/${rid}`);
  }

  return (
    <button 
      className='bg-almond-frost lg:text-xl lg:px-3 lg:py-2 text-lg px-3 py-1 rounded-md'
      type='button'
      onClick={handleClick}
    >Create Room</button>
  )
}

export default JoinGameButton;
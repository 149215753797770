import React, { useEffect, useRef, useState } from "react";
import { ESquareAnimation, TSquare } from "./grid/square/Square";
import Grid from "./grid/Grid";
import { createSquares, sleep } from "./grid/GridHelper";

interface IVisualMemoryProps {
  handleGameLost: () => void,
  currentGameSolution: number[],
  setCurrentGameSolution: (ans: number[]) => void
  transitionEnded: boolean,
  playedHint: boolean,
  level: number,
  gameFailed: boolean,
  setGameFailed: (val: boolean) => void,
  setLevel: (val: number) => void,
  setPlayedHint: (val: boolean) => void
}

function VisualMemory(props: IVisualMemoryProps) {
  const [squares, setSquares] = useState<TSquare[]>([]);
  const [squareCount, _setSquareCount] = useState<number>(25);
  const [sideCount, _setSideCount] = useState<number>(5);

  const [currentFlipped, setFlipped] = useState<number[]>([]);

  const loaded = useRef(false);

  const ROUND_RESEST_DELAY = 700;
  const NEXT_ROUND_DELAY = 500;
  const FLIP_PATTERN_DURATION = 600;
  
  useEffect(() => {
    if (props.currentGameSolution.length <= 0) {
      if (!loaded.current) {
        init([]);
      }
    } else {
      init(props.currentGameSolution);
    }
  }, []);
  
  const init = (ans: number[]) => {
    const tempSquares = createSquares(squareCount, ESquareAnimation.Flip);
    setSquares(tempSquares);

    let flipped = ans;

    if (ans.length === 0) {
      flipped = createFlipped(props.level, tempSquares);
    }

    props.setCurrentGameSolution(flipped);
    setFlipped(flipped);

    loaded.current = true;
  }

  const createFlipped = (flippedSize: number, squares: TSquare[]) => {
    const res: number[] = [];
    
    const squaresCopy = [...squares];

    let i = 0;
    while (i < flippedSize) {
      const randomIndex = Math.floor(Math.random() * squaresCopy.length);
      const randomElement: TSquare = squaresCopy[randomIndex];

      squaresCopy.splice(randomIndex, 1);
      res.push(randomElement.index);
      i++;
    }

    return res;
  }

  const flipSquares = async (flipped?: number[]) => {
    if (!props.transitionEnded) { return; }
    if (props.playedHint) { return; }

    let usedFlipped = currentFlipped;

    if (flipped !== undefined) { usedFlipped = flipped; }

    for (let i = 0; i < usedFlipped.length; i++) {
      updateSquare(usedFlipped[i], true);
    }

    await sleep(FLIP_PATTERN_DURATION);

    for (let i = 0; i < usedFlipped.length; i++) {
      updateSquare(usedFlipped[i], false);
    }

    props.setPlayedHint(true);
  }

  const updateSquare = (squareIndex: number, pressed?: boolean) => {
    const updatedSquare = squares[squareIndex];
    if (pressed !== undefined) { updatedSquare.pressed = pressed; }
    
    setSquares([
      ...squares.slice(0, squareIndex),
      updatedSquare,
      ...squares.slice(squareIndex + 1)
    ]);
  }

  const onClick = async (squareIndex: number) => {
    if (props.gameFailed || currentFlipped.length <= 0) return;

    if (!currentFlipped.includes(squareIndex)) { // fail player
      props.handleGameLost();
      return props.setGameFailed(true);
    }

    updateSquare(squareIndex, true);
    let newFlipped = currentFlipped.filter((flippedIndex) => flippedIndex !== squareIndex);
    
    /** Check if game was completed */
    if (newFlipped.length <= 0) {     
      await sleep(ROUND_RESEST_DELAY);
      
      for (let i = 0; i < squares.length; i++) {
        updateSquare(squares[i].index, false);
      }

      await sleep(NEXT_ROUND_DELAY);

      const newRound = props.level + 1;
      
      const newSquares = createSquares(squareCount, ESquareAnimation.Flip);
      newFlipped = createFlipped(Math.min(14, newRound), newSquares);
      setSquares(newSquares);
      setFlipped(newFlipped);
      
      props.setCurrentGameSolution(newFlipped);
      props.setPlayedHint(false);
      
      props.setLevel(newRound);

      flipSquares(newFlipped);
    } else {
      setFlipped(newFlipped);
    }
  }

  return (
    <div className={`relative order-1 mx-auto my-0 lg:order-2 lg:m-0 `}>
      <Grid 
        gameFailed={props.gameFailed}
        squares={squares} 
        sideCount={sideCount} 
        onClickHandler={onClick}
        onStart={flipSquares}
        loaded={props.transitionEnded && loaded.current}
      />
    </div>
  );
}

export default React.memo(VisualMemory);
export interface ISubmitButton {
  text: string,
  // handleClick: (event: React.FormEvent<HTMLInputElement>) => void
}

function SubmitButton(props: ISubmitButton) {
  return (
    <input type="submit" value={props.text} className='bg-blackcurrant mt-4 lg:text-xl lg:px-8 lg:py-2 text-lg px-6 py-1 text-gainsboro cursor-pointer' />
  );
}

export default SubmitButton;
import { gql } from "@apollo/client";

const LOGIN = gql`
  mutation Login($input: UserLoginInput!) {
    loginUser(input: $input) {
      username,
      uid,
      email
    }
  }
`

const REGISTER_USER = gql`
  mutation Register($input: UserRegisterInput!) {
    registerUser(input: $input) {
      username,
      uid,
      email
    }
  }
`

const LOGOUT_USER = gql`
  mutation Logout {
    logout
  }
`

export { LOGIN, REGISTER_USER, LOGOUT_USER };
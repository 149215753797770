import { PropsWithChildren, ReactNode, useEffect, useReducer, useState } from "react"
import { AuthContextProvider, AuthReducer, defaultAuthContextState } from "./Context";
import { useQuery } from "@apollo/client";
import { USER_SUCCESS } from "../../graphql/queries/authenticationQueries";
import { UserInfo } from "../../pages/authentication/login/Login";

export interface IAuthContextComponentProps extends PropsWithChildren {
  children: ReactNode
}

const AuthContextComponent: React.FunctionComponent<IAuthContextComponentProps> = (props) => {
  const { children } = props;

  const [AuthState, AuthDispatch] = useReducer(AuthReducer, defaultAuthContextState);

  const { loading, data } = useQuery(USER_SUCCESS);

  if (data && !AuthState.user) AuthDispatch({ type: 'login', payload: data.userSuccess as UserInfo })

  if (loading) return <></>

  return <AuthContextProvider value={{ AuthState, AuthDispatch }}>{ children }</AuthContextProvider>;
}

export default AuthContextComponent;
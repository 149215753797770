import SequenceMemory from "../components/minigames/SequenceMemory";

import { Player } from "../components/minigames/players/PlayerListItem";
import Chat from "../components/minigames/chat/Chat";
import Header from "../components/minigames/header/Header";
import { useEffect, useState } from "react";
import { Message } from "../components/minigames/chat/Message";
import VisualMemory from "../components/minigames/VisualMemory";

function MinigamePlayground() {
  return (<div>
    <div className="relative w-full flex flex-col justify-center items-center px-10 pt-10">
      <div className="w-full flex flex-col lg:flex-row justify-between mx-3">
        {/* <SequenceMemory /> */}
      </div>
    </div>
  </div>);
}

export default MinigamePlayground;
import React from "react";

interface IPlayerCountButtonProps {
  handleClick: (event: React.MouseEvent<HTMLElement>, count: number) => void,
  playerCountOption: number,
  currentPlayerCountOption: number
}

function PlayerCountButton(props: IPlayerCountButtonProps) {
  return (
    <button 
      className={'lg:text-md lg:px-3 lg:py-1 text-md px-3 py-1 mx-4 rounded-md transition-colors ' + (props.currentPlayerCountOption === props.playerCountOption ? 'bg-[#675B51]' : 'bg-almond-frost')}
      type='button'
      onClick={(event) => props.handleClick(event, props.playerCountOption)}
    >{props.playerCountOption}</button>
  )
}

export default PlayerCountButton;
import { Socket } from 'socket.io-client';
import { createContext } from 'react';
import { Player } from '../../components/minigames/players/PlayerListItem';
import { Message } from '../../components/minigames/chat/Message';
import { GameType } from '../../pages/game/Game';

export type SpectatingInput = {
  input: number,
  player: Player,
  ans: number[]
}

export interface ISocketContextState {
  socket: Socket | undefined;
  uid: string;
  rid: string;
  users: Player[];
  messages: Message[];
  currentGame: GameType,
  chosenGames: GameType[];
  gameWinner: Player | undefined,
  gameFinished: boolean,
  latestSpectatingInput: SpectatingInput | undefined
}

export const defaultSocketContextState: ISocketContextState = {
  socket: undefined,
  uid: "",
  rid: "",
  users: [],
  messages: [],
  currentGame: "Visual Memory",
  chosenGames: [],
  gameWinner: undefined,
  gameFinished: false,
  latestSpectatingInput: undefined
}

export type TSocketContextActions = 'update_socket' | 'update_uid' | 'update_rid' | 'update_users' | 'remove_user' | 'add_chat_message' | 'update_messages' | 'update_chosen_games' | 'update_game_winner' | 'update_current_game' | 'finish_game' | 'update_latest_spectating_input';

export type TSocketContextPayload = string | Player[] | Socket | Message | Message[] | Player | undefined | GameType[] | SpectatingInput;

export interface ISocketContextActions {
  type: TSocketContextActions;
  payload: TSocketContextPayload;
}

export const SocketReducer = (state: ISocketContextState, action: ISocketContextActions): ISocketContextState => {
  // console.log(`Message Received - Action: ${action.type} - Payload: `, action.payload);

  switch (action.type) {
    case 'update_socket':
      return { ...state, socket: action.payload as Socket };
    case 'update_uid':
      return { ...state, uid: action.payload as string };
    case 'update_rid':
      return { ...state, rid: action.payload as string };
    case 'update_users':
      return { ...state, users: action.payload as Player[] };
    case 'remove_user':
      return { ...state, users: state.users.filter((player) => player.uid !== (action.payload as string)) };
    case 'add_chat_message':
      return { ...state, messages: [...state.messages, action.payload as Message] };
    case 'update_messages':
      return { ...state, messages: [...action.payload as Message[] ] };
    case 'update_chosen_games':
      return { ...state, chosenGames: action.payload as GameType[] };
    case 'update_current_game':
      return { ...state, currentGame: action.payload as GameType };
    case 'update_game_winner':
      return { ...state, gameWinner: action.payload as Player };
    case 'finish_game':
      return { ...state, gameFinished: true }; 
    case 'update_latest_spectating_input':
      return {...state, latestSpectatingInput: action.payload as SpectatingInput };
    default:
      return { ...state };
  }
}

export interface ISocketContextProps {
  SocketState: ISocketContextState;
  SocketDispatch: React.Dispatch<ISocketContextActions>;
}

const SocketContext = createContext<ISocketContextProps>({
  SocketState: defaultSocketContextState,
  SocketDispatch: () => {}
});

export const SocketContextConsumer = SocketContext.Consumer;
export const SocketContextProvider = SocketContext.Provider;

export default SocketContext;
import { PropsWithChildren, ReactNode, useReducer } from "react";
import { GameContextProvider, GameReducer, defaultGameContextState } from "./Context";

export interface IGameContextComponentProps extends PropsWithChildren {
  children: ReactNode
}

const GameContextComponent: React.FunctionComponent<IGameContextComponentProps> = (props) => {
  const { children } = props;

  const [GameState, GameDispatch] = useReducer(GameReducer, defaultGameContextState);

  return <GameContextProvider value={{ GameState, GameDispatch }}>{ children }</GameContextProvider>;
}

export default GameContextComponent;
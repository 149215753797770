import { useContext, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import Game from '../pages/game/Game'; // '../../pages/game/Game';
import SocketContextComponent from '../contexts/socket/Component';
import MinigamePlayground from '../pages/MinigamePlayground';
import Login, { UserInfo } from '../pages/authentication/login/Login';
import Register from '../pages/authentication/register/Register';
import Home from '../pages/Home';

import GuardedRoute from "./GuardedRoutes";
import AuthContextComponent from "../contexts/authentication/Component";
import AuthContext from "../contexts/authentication/Context";
import GameContextComponent from "../contexts/game/Component";

function RouterComponent() {
  return (
    <AuthContextComponent>
      <GameContextComponent>
        <AuthContext.Consumer>
          {
            context => (
              <Routes>
                <Route path="/" element={
                  <Home user={context.AuthState.user} />
                } />
                <Route path="/game/:rid" element={
                  <SocketContextComponent>
                    <Game />
                  </SocketContextComponent>
                } />
                <Route element={
                  <GuardedRoute isRouteAccessible={!context.AuthState.user} redirectRoute="/" />
                }>
                  <Route path="/login" element={
                    <Login />
                  } />

                </Route>
                <Route element={
                  <GuardedRoute isRouteAccessible={!context.AuthState.user} redirectRoute="/" />
                }>
                  <Route path="/sign-up" element={
                    <Register />
                  }/>
                </Route>
                <Route path="minigame" element={
                  <MinigamePlayground />
                }
                />
              </Routes>
            )
          }
        </AuthContext.Consumer>
      </GameContextComponent>
    </AuthContextComponent>
  )
}

export default RouterComponent;
import React, { useState, useEffect, useRef } from "react";

import Grid from "./grid/Grid";
import { ESquareAnimation, TSquare } from "./grid/square/Square";
import { createSquares, sleep } from "./grid/GridHelper";

interface ISequenceMemoryProps {
  handleGameLost: () => void,
  currentGameSolution: number[],
  setCurrentGameSolution: (ans: number[]) => void,
  transitionEnded: boolean,
  playedHint: boolean,
  level: number,
  gameFailed: boolean,
  setGameFailed: (val: boolean) => void,
  setLevel: (val: number) => void,
  setPlayedHint: (val: boolean) => void,
}

function SequenceMemory(props: ISequenceMemoryProps) {
  const [squares, setSquares] = useState<TSquare[]>([]);
  const [squareCount, _setSquareCount] = useState<number>(9);
  const [sideCount, _setSideCount] = useState<number>(3);

  const [currentSequence, setCurrentSequence] = useState<number[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  
  const loaded = useRef(false);

  const PLAY_PATTERN_DURATION = 300;
  const ON_CLICK_DELAY = 160;
  const NEXT_ROUND_DELAY = 200;

  useEffect(() => {
    if (props.currentGameSolution.length <= 0) {
      if (!loaded.current) {
        init([]);
      }
    } else {
      init(props.currentGameSolution);
    }
  }, []);

  const init = (ans: number[]) => {
    const tempSquares = createSquares(squareCount, ESquareAnimation.Highlight);
    setSquares(tempSquares);

    let flipped = ans;

    if (ans.length === 0) {
      flipped = createSequence(props.level, tempSquares);
    }

    props.setCurrentGameSolution(flipped);
    setCurrentSequence(flipped);

    loaded.current = true;
  }

  const createSequence = (patternLength: number, squares: TSquare[]) => {
    const res = [];

    for (let j = 0; j < patternLength; j++) {
      const randomElement: TSquare = squares[Math.floor(Math.random() * squares.length)];
      res.push(randomElement.index);
    }

    return res;
  }

  const playSequence = async (sequence?: number[]) => {
    if (!props.transitionEnded) { return; }
    if (props.playedHint) { return; }

    let usedSequence = currentSequence;

    if (sequence !== undefined) { usedSequence = sequence; }

    for (let i = 0; i < usedSequence.length; i++) {
      // update squared to be pressed
      updateSquare(usedSequence[i], true);
      await sleep(PLAY_PATTERN_DURATION);

      updateSquare(usedSequence[i], false);
      await sleep(PLAY_PATTERN_DURATION);
    }

    props.setPlayedHint(true);
  }

  const updateSquare = (squareIndex: number, pressed?: boolean) => {
    const updatedSquare = squares[squareIndex];

    if (pressed !== undefined) { updatedSquare.pressed = pressed; }
    
    setSquares([
      ...squares.slice(0, squareIndex),
      updatedSquare,
      ...squares.slice(squareIndex + 1)
    ]);
  }

  const onClick = async (squareIndex: number) => {
    if (props.gameFailed || currentSequence.length <= 0) return;

    if (currentSequence[currentIndex] !== squareIndex) { // fail the player
      props.handleGameLost();
      return props.setGameFailed(true);
    }

    updateSquare(squareIndex, true);
    await sleep(ON_CLICK_DELAY);
    updateSquare(squareIndex, false);

    const newIndex = currentIndex + 1

    /** Check if game was completed */
    if (newIndex === currentSequence.length) {
      await sleep(NEXT_ROUND_DELAY);
      
      const newRound = props.level + 1;
      
      const newSquares = createSquares(squareCount, ESquareAnimation.Highlight);
      const newSequence = createSequence(newRound, newSquares);
      setSquares(newSquares);
      setCurrentSequence(newSequence);
      props.setCurrentGameSolution(newSequence);
      props.setPlayedHint(false);

      props.setLevel(newRound);

      setCurrentIndex(0);
      playSequence(newSequence);
    } else {
      setCurrentIndex(newIndex);
    }
  }

  return (
    <div className={`relative order-1 mx-auto my-0 lg:order-2 lg:m-0 `}>
      <Grid 
        squares={squares} 
        sideCount={sideCount} 
        onClickHandler={onClick}
        onStart={playSequence}
        gameFailed={props.gameFailed}
        loaded={props.transitionEnded && loaded.current}
      />
    </div>
  );
}

export default SequenceMemory;
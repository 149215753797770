import AlternativeAuthentication from "../../../components/authentication/AlternativeAuthentication";
import InputField, { FieldType } from "../../../components/authentication/InputField";
import SubmitButton from "../../../components/authentication/SubmitButton";

import GoogleSVG from '../../../assets/authentication/google.svg';
import { useNavigate } from "react-router-dom";
import { useContext, useReducer, useState } from "react";
import { ApolloError, useMutation } from "@apollo/client";
import { REGISTER_USER } from "../../../graphql/mutations/authenticationMutations";
import AuthContext, { AuthReducer, defaultAuthContextState } from "../../../contexts/authentication/Context";
import { UserInfo } from "../login/Login";

function Register() {
  const navigate = useNavigate();
  const [registerUser] = useMutation(REGISTER_USER);

  const dispatch = useContext(AuthContext).AuthDispatch;

  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [errorMsg, setErrorMsg] = useState("");

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError();
    setEmail(event.target.value);
  }

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError();
    setUsername(event.target.value);
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError();
    setPassword(event.target.value);
  }

  const clearError = () => {
    if (errorMsg.length === 0) return;
    setErrorMsg("");
  }

  const onButtonClick = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const user: UserInfo = (await registerUser({ variables: { input: { username, password, email } } })).data.registerUser;
      dispatch({ type: 'register', payload: user })
      navigate('/');
    } catch (e: any) {
      const error: ApolloError = e;
      setErrorMsg(error.message);
    }
  }

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="w-96 bg-white-smoke rounded-md flex flex-col px-8 py-8 font-montserrat">
        <h1 className="text-2xl font-bold mx-auto">SIGN UP</h1>
        <form className="w-full flex flex-col items-center" onSubmit={(e) => onButtonClick(e)}>
          {errorMsg.length > 0 ? <div className="text-red-400 text-sm">{errorMsg}</div> : <></>}
          <InputField handleChange={handleEmailChange} type={FieldType.Email} placeholder="📧 Email" />
          <InputField handleChange={handleUsernameChange} type={FieldType.Text} placeholder="👤 Username" />
          <InputField handleChange={handlePasswordChange} type={FieldType.Password} placeholder="🔑 Password" />
          <SubmitButton text="Sign Up" />
        </form>
        {/* <div className="text-sm text-center py-4 opacity-70">Or Sign Up With</div>
        <div className="mx-auto my-0">
          <AlternativeAuthentication imagePath={GoogleSVG} authName="Google" />
        </div> */}
        <span className="pt-4 text-sm inline">Already have an account?
          <span> </span>
          <span className="underline underline-offset-1 cursor-pointer" onClick={() => navigate('/login')}> Log in here.</span>
        </span>
      </div>
    </div>
  );
}

export default Register;
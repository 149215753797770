import AlternativeAuthentication from "../../../components/authentication/AlternativeAuthentication";
import InputField, { FieldType } from "../../../components/authentication/InputField";
import SubmitButton from "../../../components/authentication/SubmitButton";

import GoogleSVG from '../../../assets/authentication/google.svg';
import { useNavigate } from "react-router-dom";
import { useContext, useReducer, useState } from "react";

import { ApolloError, useMutation } from "@apollo/client";
import { LOGIN } from "../../../graphql/mutations/authenticationMutations";
import AuthContext, { AuthReducer, LoginInput, defaultAuthContextState } from "../../../contexts/authentication/Context";

export type LoginResult = {
  message: string,
  user: UserInfo
}

export type UserInfo = {
  username: string,
  email: string,
  uid: string
}

function Login() {
  // DEsign here: shttps://colorlib.com/wp/wp-content/uploads/sites/2/login-form-v11.jpg
  const navigate = useNavigate();
  const [login] = useMutation(LOGIN);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [errorMsg, setErrorMsg] = useState("");

  // const [AuthState, AuthDispatch] = useReducer(AuthReducer, defaultAuthContextState);
  const dispatch = useContext(AuthContext).AuthDispatch;

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError();
    setUsername(event.target.value);
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError();
    setPassword(event.target.value);
  }

  const clearError = () => {
    if (errorMsg.length === 0) return;
    setErrorMsg("");
  }

  const onButtonClick = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const loginInfo: LoginInput = { username, password }

    try {
      const user: UserInfo = (await login({ variables: { input: loginInfo } })).data.loginUser;
      dispatch({ type: 'login', payload: user });
      navigate('/');
    } catch (e: any) {
      const error: ApolloError = e;
      setErrorMsg(error.message);
    }

  }

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="w-96 bg-white-smoke rounded-md flex flex-col px-8 py-8 font-montserrat">
        <h1 className="text-2xl font-bold mx-auto">LOGIN</h1>
        <form className="w-full flex flex-col items-center" onSubmit={(e) => onButtonClick(e)}>
          {errorMsg.length > 0 ? <div className="text-red-400 text-sm">{errorMsg}</div> : <></>}
          <InputField handleChange={handleUsernameChange} type={FieldType.Text} placeholder="👤 Username" />
          <InputField handleChange={handlePasswordChange} type={FieldType.Password} placeholder="🔑 Password" />
          <SubmitButton text="Log In" />
        </form>
        <span className="pt-4 text-sm inline">Don't have an account?
          <span> </span>
          <span className="underline underline-offset-1 cursor-pointer" onClick={() => navigate('/sign-up')}>Sign Up here.</span>
        </span>
      </div>
    </div>
  );
}

export default Login;
export type Player = {
  uid: string,
  socketId: string,
  username: string,
  hasLost: boolean
}

export interface IPlayerListItemProps {
  player: Player,
  active: boolean,
  updateCurrentPlayer: Function,
  gameFailed: boolean
}

function PlayerListItem(props: IPlayerListItemProps) {
  /** Add a hover here */
  return (
    <div className={
      (props.active ? "bg-rum " : "bg-amethyst-smoke ") + 
      `h-fit text-gainsboro px-3 py-3 last:mb-0 cursor-pointer w-full`
    } onClick={() => { 
      // if (props.player.hasLost) return;
      // props.updateCurrentPlayer(props.player);
    }}>
      <div className="w-full">
        <h2 className={((props.player.hasLost) ? 'line-through ' : '') + "font-montserrat max-w-[16rem] font-bold whitespace-nowrap text-ellipsis overflow-hidden align-center inline-block align-middle"}>{props.player.username}</h2>
        <div className="inline-block align-middle ml-1">{(props.player.hasLost) ? '💀' : ''}</div>
      </div>
    </div>
  );
}

export default PlayerListItem;
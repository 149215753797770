import { useEffect, useRef, useState, memo } from 'react';
import { GameType } from '../../../pages/game/Game';
import './_style.scss';
import { Player } from '../players/PlayerListItem';

interface IGamePickingProps {
  chosenGames: GameType[],
  finishedGames: GameType[],
  updateFinishedGames: (games: GameType[]) => void,
  currentGame: GameType,
  gameWinner: Player | undefined,
  gameFinished: boolean,
  handleTransitionFinished: () => void
}

function GamePicking(props: IGamePickingProps) {
  const ref = useRef(null);

  const [hasListener, setHasListener] = useState(false);

  useEffect(() => {
    if (!hasListener) {
      (ref.current! as HTMLElement).addEventListener('animationend', props.handleTransitionFinished);
      setHasListener(true);
    }

  }, []);
  
  return (
    // slide-full-anim
    <div ref={ref} className={'game-picking z-[15] slide-full-anim flex flex-col items-center justify-center ' + (props.gameFinished ? 'slide-down-anim' : '')}>
      { props.gameWinner ? <div className='font-montserrat lg:text-4xl text-3xl font-bold'>{ `👑 ` + props.gameWinner.username }</div> : <></>}
      {
        !props.gameFinished ? props.chosenGames.map((chosenGame: GameType, index: number) => {
          
          return (
            <div key={index} className='flex'>
              <input id="02" type="checkbox" name="r" value="2" onChange={() => {}} checked={props.finishedGames.includes(chosenGame)} />
              <label htmlFor="02" className={`font-poppins lg:text-2xl text-xl ` + (props.currentGame === chosenGame ? 'underline' : '')}>Game {index + 1}: {chosenGame}</label>
            </div>
          )
        }) : (
          <div>
            <h1 className='font-poppins lg:text-3xl text-2xl mt-10'>That's it! Thanks for playing!</h1>
          </div>
        )
      }
    </div>
  );
}

export default memo(GamePicking);
import { gql } from "@apollo/client";

const CREATE_ROOM = gql`
  mutation createRoom($input: GameSettings!) {
    createRoom(input: $input) {
      rid
    }
  }
`

export { CREATE_ROOM }